import { ChevronDownIcon } from "lucide-react";
import type { Ref } from "react";
import { forwardRef } from "react";
import Skeleton from "react-loading-skeleton";

import cn from "../../../util/cn.js";
import FlexibleSpacer from "../../FlexibleSpacer/FlexibleSpacer.js";
import HStack from "../../Stack/HStack.js";
import type TriggerProps from "../models/TriggerProps.js";

const SingleSelectTrigger = forwardRef(
  (
    {
      options: { selectedOptions, triggerIcon, triggerClassName, placeholder },
      ...rest
    }: TriggerProps<any>,
    forwardedRef: Ref<HTMLButtonElement>
  ) => (
    <button
      type="button"
      {...rest}
      ref={forwardedRef}
      className={cn(
        "min-w-[100px] rounded-md border bg-surface px-4 hover:bg-surface-muted shadow-sm data-[state=open]:bg-surface-muted disabled:bg-surface-muted h-[calc(1em+28px)]",
        triggerClassName
      )}
    >
      <HStack gapSm className="h-full">
        {selectedOptions.length > 0 && (
          <HStack gapSm>
            {(triggerIcon ?? selectedOptions[0].icon) && (
              <div className="flex-none -ml-1">{triggerIcon ?? selectedOptions[0].icon}</div>
            )}
            {selectedOptions[0].isLoading ? (
              <Skeleton width={100} />
            ) : (
              <div className="text-sm font-medium">{selectedOptions[0].label}</div>
            )}
          </HStack>
        )}
        {selectedOptions.length === 0 && <div className="text-sm font-medium">{placeholder}</div>}
        <FlexibleSpacer />
        <ChevronDownIcon className="w-4" />
      </HStack>
    </button>
  )
);

export default SingleSelectTrigger;
